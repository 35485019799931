body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.search-form {
  /*display: flex;*/
}

.search-form .ant-form-item {
  margin-right: 15px;
}

.error {
  display: block;
  margin-bottom: 10px;
  color: red;
}

.user-card {
  border: 3px solid #1890ff;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 15px;
}

.user-card .ant-btn {
  margin-right: 15px;
}

.search-result,
.search-box {
  width: 300px;
  margin: 0 auto;
}

.add-member {
  width: 100%;
}